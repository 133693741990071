<template>
    <div>
        <template v-if="orderProducts.length">
            <AppOrderView
                :date="currentDate"
                :products="orderProducts"
            />

            <v-container fluid>
                <v-row>
                    <v-col>
                        <v-textarea
                            name="comment_delivery"
                            rows=2
                            label="Ваши пожелания по времени доставки"
                            v-model="commentDelivery"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            name="comment_quality"
                            rows=2
                            label="Ваш комментарий по качеству обслуживания"
                            v-model="commentQuality"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-container>

            <v-container fluid>
                <v-row no-gutters>
                    <v-col class="py-1" cols="12">
                        <v-btn
                            block
                            color="primary"
                            :disabled="makeOrderBtnDisabled"
                            @click="makeOrder()"
                        >
                            Оформить
                        </v-btn>
                    </v-col>
                    <v-col class="py-1" cols="12">
                        <v-btn
                            block
                            color="secondary"
                            text
                            @click="isShownClearConfirmDialog = true"
                        >
                            Очистить
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>

            <AppVDialog
                type="alert"
                :isShowed.sync="isShownClearConfirmDialog"
                title="Очистить заказ?"
                :actionItems="clearConfirmDialogActionItems"
            />

            <AppVDialog
                type="alert"
                :isShowed.sync="isShownCompliteConfirmDialog"
                title="Заказ оформлен."
                :actionItems="[{
                    text: 'Да',
                    click: () => this.clearAndGotoCatalog(),
                }, {
                    text: 'Нет, спасибо',
                    click: () => this.clearAndGotoOrders(),
                }]"
            >
                Оформит новый заказ?
            </AppVDialog>
        </template>
        <template v-else>
            <v-container class="py-12">
                <div class="d-flex justify-center">
                    <AppVIcon icon="sadTear" size="170"/>
                </div>
                <div class="text-center py-8">
                    <h4>Вы ничего не заказали.</h4>
                    <div>Ну обидно же!</div>
                </div>
                <v-btn
                    block
                    color="primary"
                    :to="{name: 'purchaseCatalog'}"
                >
                    Сделать заказ
                </v-btn>
            </v-container>
        </template>
    </div>
</template>

<script>
    import {
        VBtn,

        VContainer,
        VRow,
        VCol,

        VTextarea,
    } from 'vuetify/lib'
    import AppVDialog from '@/components/vuetify/Dialog'
    import AppVIcon from '@/components/vuetify/Icon'

    import AppOrderView from '@/components/app/order/OrderView'

    import {
        cloneDeep as _cloneDeep,
        filter as _filter,
        forEach as _forEach,
        map as _map,
    } from 'lodash'

    import moment from 'moment'

    import { mapGetters, mapMutations } from 'vuex'

    import OrderService from '@/services/orders.service'

    import OrderItemModel from '@/models/OrderItemModel'

    export default {
        name: 'OrderIndex',

        components: {
            VBtn,

            AppVDialog,

            VContainer,
            VRow,
            VCol,

            VTextarea,

            AppVIcon,

            AppOrderView,

        },

        data() {
            return {
                isShownClearConfirmDialog: false,
                isShownCompliteConfirmDialog: false,
                makeOrderBtnDisabled: false,
                commentQuality: '',
                commentDelivery: '',
            }
        },
        computed: {
            ...mapGetters({
                getProducts: 'product/getProducts',
                getQuantity: 'product/getSelectedQuantity'
            }),

            appBarData() {
                return {
                    dark: true,
                    navigationIcon: {
                        type: 'nav',
                    },
                    title: 'Оформление заказа',
                }
            },

            currentDate() {
                return moment()
            },

            orderProducts() {
                const orderProducts = _filter(_cloneDeep(this.getProducts), Product => {
                    return this.getQuantity(Product.id) > 0
                })

                return _map(orderProducts, Product => {
                    return new OrderItemModel({
                        product_id: Product.id,
                        name: Product.name,
                        price: Product.price,
                        quantity: this.getQuantity(Product.id),
                    })
                })
            },

            clearConfirmDialogActionItems() {
                return [
                    {
                        text: 'Отмена',
                        click: () => this.hideClearConfirmDialog(),
                    },
                    {
                        text: 'Очистить',
                        click: () => this.clearSelectedQuantity(),
                    },
                ]
            },
        },

        mounted() {
            this.setAppBarData()
        },

        methods: {
            ...mapMutations({
                setAppBar: 'ui/setAppBar',
                setProduct: 'product/setProduct',
                clearSelectedQuantity: 'product/clearSelectedQuantity',
            }),

            clearAndGotoOrders() {
                this.clearSelectedQuantity()
                this.isShownCompliteConfirmDialog = false
                this.$router.push({name: 'purchaseHistory'})
            },

            clearAndGotoCatalog() {
                this.clearSelectedQuantity()
                this.isShownCompliteConfirmDialog = false
                this.$router.push({name: 'purchaseCatalog'})
            },

            setAppBarData() {
                this.setAppBar(this.appBarData)
            },

            hideClearConfirmDialog() {
                this.isShownClearConfirmDialog = false
            },

            makeOrder() {
                this.makeOrderBtnDisabled = true;

                OrderService.create({
                    items: _map(this.orderProducts, orderItem => {
                        return {
                            product_id: orderItem.productId,
                            quantity: orderItem.quantity,
                        }
                    }),
                    comment_delivery: this.commentDelivery,
                    comment_quality: this.commentQuality,
                })
                    .then(() => {
                        this.isShownCompliteConfirmDialog = true
                        this.makeOrderBtnDisabled = false
                    })
                    .catch(error => {
                        this.makeOrderBtnDisabled = false
                        this.showToastError(error.errors)
                    })
            }
        },
    }
</script>
